
.sideBar {

    padding-top: 15px;
    transition: 0.1s;
    padding-left: 100px;
    background-color: #f7f7f7;
    border-right-style: solid;
    border-right-color: darkgrey;
    border-right-width: 1px;


}

.navigationBar{


    border-bottom-style: solid;
    border-bottom-color: darkgrey;
    border-bottom-width: 1px;
}

.navbar-light .navbar-toggler.navToggle {


    border-color: white;
}

.navbar-nav a {

    font-size: 10px;
    color: rgba(0, 0, 0, .55);
    text-decoration: none;
}

.navbar-nav .selected {

    color: #2ac4ea;
    font-weight: bolder;
}


@media only screen and (max-width: 600px) {
    .empyreanImg img {
        margin-left: 60%;
    }
}

@media only screen and (max-width: 3000px) {
    .empyreanImg img {
        margin-left: 25%;
        margin-top: 1px;
    }
}

.threeDotsMobile {

    margin-right: 5%;
}

.listIcon {

    margin-left: 7%;
    cursor: pointer;
}








.leftNav {

    margin-right: 20px;
}

.navbar-nav a{

    font-size: 12px;

}

.pointer{

    cursor: pointer;
}

.pointer:hover{

    color: #2ac4ea;
}

.leftNavIcon {

    margin-top: 8px;

}

.loginNav {

    padding-left: 2px;
}

.leftNavbar {

    padding-bottom: 3px;

}

.leftNavItem {

    margin-right: 35px;
    cursor: pointer;

    margin-top: 8px;
}

.navIcon{
    position: relative;
}

.notification {

    color: red;
}



.notificationIcon:hover{

    color: red;
}



.settings:hover {

    color: darkgrey;

}



.navIcon .badge {
    position: absolute;
    top: 2px;
    right: 27px;
    padding: 2px 4px;
    border-radius: 50%;
    font-size: 11px;
    background-color: red;
    color: white;
}


@media (max-width: 768px) {
    .navbar-collapse {
        background-color: white;
        border-color: black;
        position: absolute;
        top: 54px;
        left: -100%;
        width: 150%;
        transition: all 0.4s ease;
        display: block;
        opacity: 0.8;
        padding-left: 20%;
    }

    .navbar-collapse.collapsing {
        height: auto;
        left: -100%;
        margin-left: 1px;
        transition: all 0.2s ease;
        opacity: 0.9;
    }

    .navbar-collapse.show {
        margin-left: 100%;
        transition: all 0.2s ease;
        opacity: 1;
    }
}

@media only screen and (max-width: 600px) {
    .empyreanImg img {
        margin-left: 75%;
    }
}

@media only screen and (max-width: 525px) {
    .empyreanImg img {
        margin-left: 32%;
    }
}

@media only screen and (max-width: 325px) {

    .empyreanImg img {
        margin-left: 25%;
    }
}

.empyreanImg img {

    width: 85px;
    height: 45px;
}

.hideSideBar {

    display: none;
}

.showSideBar {

    font-size: 12px;
}

.sideBarMobile {

    margin-top: 20px;
}

.showSideBarMobile {
    font-size: 12px;
    margin-left: 3px;
}

.sideBarLabel :hover {

    color: #2ac4ea;
}

.sideBarLabel {

    padding-left: 12px;
    margin-top: 7px;
}



.sideBarTooltip {

    margin-left: 8px;

    font-size: 10px;
    color: rgba(0, 0, 0, .55);

}

.dropdown-menu.show {

    font-size: 11px;
    width: 132px;
    min-width: 12%;
}

.messageWindow {


    margin-left: 45%;
    position: absolute;
    background-color: white;
    z-index: 1;
}

.smallMessage{

    font-size: 8px;
}

.main{

    height:90vh;
    overflow: auto;
}









