.heatmap * {
    box-sizing: border-box;
}

.heatmap .header{
    margin-bottom: 30px;

}

.heatmap{

    padding-block: 20px;
    max-width: unset !important;
    overflow: hidden;
}

.heatmap .logo img{
    font-family: 'Seven Segment';

    max-width: 100px;
}

.heatmap p{
    font-family: 'Seven Segment';

    margin: unset;
}

.heatmap .cardLayout{
    font-family: 'Seven Segment';

    margin-top: 2px;
    color: #a7d9fa;
}

.heatmap .cardData{
    font-family: 'Seven Segment';

    margin-top: 5%;
    margin-bottom: 5%;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
}

.heatmap .cardDescription{
    font-family: 'Seven Segment';

    text-align: center;
    font-size: 16px;
    padding-bottom: 15px;
}

.heatmap .heatMapTitle{
    font-family: 'Seven Segment';

    color: #a7d9fa;
    font-size: 40px;
    margin-bottom: 20px;
}

.heatmap .statsTitle{
    font-family: 'Seven Segment';

    text-align: center;
    color: #a7d9fa;
}



.heatmap .heatStats{
    font-family: 'Seven Segment';

    display: flex;
    height: 100%;
    flex-direction: column;
    margin-top: 5%;
    gap: 60px;
}


.heatmap .custom-chart{
    font-family: 'Seven Segment';

    animation: fadeIn 3s;
}

@media screen and (min-width: 2000px) {
    .heatmap .logo img{
        max-width: 200px;
    }
}
