.rightChart{

    margin-left: 10%;
    margin-top: 8%;
}

.pieChart{

    margin-left: 20%;
    margin-top: 12%;
    margin-bottom: 5%;

}

.barChart{

    margin-left: 15%;
    margin-top: 7%;
    margin-right: 10%;

}

.rightDataLabel{

    margin-left: 12%;
    margin-right: 3%;
    padding-bottom: 3%;
}

.midChart{

    margin-left: 25%;

    margin-top: 20%;
}

.leftChart{

    margin-top: 2%;

    margin-right: 5%;
}






.cardL{
    margin-top: 5px;
    margin-right: 1px;
    margin-bottom: 5px;

}







.dashboardTitle{

    margin-left: 4%;
    margin-top: 38px;
}



.card-body {

    height: 120px;
    color: dimgray;
    border-radius: 4px;
    cursor: pointer;



}

.leftCard{

    margin-left: 4%;
}

.rightCard{

    margin-right: 4%;
}

.card .card-body :hover {
    color: cornflowerblue;

}

.card:hover {
    color: cornflowerblue;
    border-color: cornflowerblue;
    box-shadow: 0 2px 2px 2px darkgrey;
}





