.prediction  {
    box-sizing: border-box;
}

.prediction .header{
    margin-bottom: 30px;
}

.prediction{

    padding-block: 20px;
    max-width: unset !important;
    overflow: hidden;
}

.prediction .cardLayout{
    font-family: 'Seven Segment';

    margin-top: 2px;
    color: #a7d9fa;
}

.prediction .cardData{
    font-family: 'Seven Segment';

    margin-top: 7%;
    margin-bottom: 5%;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
}

.prediction .predStats{
    font-family: 'Seven Segment';

    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
}

.prediction .cardDescription{
    font-family: 'Seven Segment';

    text-align: center;
    font-size: 16px;
    padding-bottom: 15px;
}

.prediction .statsTitle{
    font-family: 'Seven Segment';

    text-align: center;
    color: #a7d9fa;
}

.prediction .logo img{
    max-width: 100px;
}

.prediction p{

    margin: unset;
}

.prediction .predictionTitle{
    font-family: 'Seven Segment';

    color: #a7d9fa;
    font-size: 40px;
    margin-bottom: 20px;
}

.prediction .predCharts{
    display: flex;
    flex-direction: column;
    gap: 20px;
    animation: fadeIn 3s;
}

