.overwatch * {
  box-sizing: border-box;
}

.overwatch .header{
  margin-bottom: 10px;
}

.overwatch{
  padding-block: 20px;
  display: grid;
  gap: 10px;
  max-width: unset !important;
  overflow: hidden;
}

.overwatch p{
  margin: unset;
}

.overwatch .logo img{
  max-width: 100px;
}

.overwatch .info{
  display: flex;
  align-items: center;
  justify-content: right;
}

.overwatch .highlightMarker{
  background-color: #55b1e7 !important;
  transition: all 300ms ease-in;
}

.overwatch .deviceInfo{
  font-size: 14px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  background-color: rgba(133, 131, 131, 0.3);
  padding: 4px;
  border-radius: 8px;
  color: white !important;

}

/*.overwatch .logMessages{*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  position: relative;*/
/*  margin-inline: 0px;*/
/*  margin-top: 10px;*/
/*  background-color: rgba(151, 151, 155, 0.18);*/
/*  padding: 10px 10px;*/
/*  border-radius: 10px;*/
/*  box-shadow: 0px 0px 2px 1px rgb(137 129 129 / 50%) !important;*/
/*}*/


.overwatch .messageCard{
  min-height: unset !important;
  height: fit-content;
  font-family: 'Seven Segment';
  color: #a7d9fa;


}

.oncoTitle{

  font-family: 'Seven Segment';
  color: #a7d9fa;
  text-align: center;

}

.overwatch .card-subtitle{
  font-size: 0.8rem !important;
  color: #96cecc !important;
}

.overwatch .card-body{
  cursor: unset;
  padding: 10px !important;
  background-color: #183d56;
  color: #96cecc;
  outline: 1px solid #96cecc;
}

.overwatch .new-message{
  animation: fadeIn 2s;
  font-family: 'Seven Segment';


}

@keyframes fadeIn {
  0% {
    opacity: 0.1;
  }
  100%{
    opacity: 1;
  }
}

/*.overwatch .slider_wrap{*/
/*  overflow: hidden;*/
/*  display: flex;*/
/*  gap: 10px;*/
/*  width: 100%;*/
/*  padding-left: 100%;*/
/*  box-sizing: content-box;*/
/*  background-color: rgb(16, 22, 38);*/
/*  border-radius: 10px;*/
/*  box-shadow: 0px 0px 2px 1px rgb(16 22 38 / 50%) !important;*/
/*}*/

.overwatch .messageItem{
  margin-top: 10px;
  font-size: 30px;
  text-align: right;
  opacity: 0;
  font-family: 'Seven Segment';

}

.slideMessage{
  animation: slideMessage 15s;
  font-family: 'Seven Segment';

}

.showDeviceName{
  animation: DeviceName ease-in 12s;
}

.overwatch .messageTitle{
  font-family: 'Seven Segment';

  color: white;
  text-align: center;
  width: 100%;
  font-size: 35px;
  opacity: 0;
}
.overwatch .slider{
  color: white;
  white-space: nowrap;
  flex-direction: column;
  min-height: 30px;
  box-sizing: content-box;
}

@keyframes DeviceName {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  80%{
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slideMessage {
  0% {
    transform: translate3d(20%, 0, 0);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: translate3d(-120%, 0, 0);
    opacity: 1;
  }
}


@media screen and (min-width: 2000px) {
  .overwatch .logo img{
    max-width: 200px;
  }
}
