
.overWatchActivity{
  color: #99ddff;

  padding-block: 20px;
  display: grid;
  gap: 10px;
  max-width: unset !important;
}


.overWatchActivity p{
  margin: unset;
}

.overWatchActivity .deviceTab{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 0px 2px 1px rgb(137 129 129 / 50%) !important;
  transition: all 300ms ease;
  padding: 15px 2px;
  background-color: #e1e1e1;
}

.overWatchActivity .header{
  margin-bottom: 10px;
  padding-top: 10px;
}

.overWatchActivity .logo img{
  max-width: 100px;
}

.overWatchActivity .deviceInfoList{
  font-family: 'Seven Segment';

  display: flex;
  flex-direction: column;
  margin-inline-start: 10px;
}

.deviceInfo{
  font-family: 'Seven Segment';

}

.overWatchActivity .deviceInfoList .title{
  font-family: 'Seven Segment';

  display: flex;
  font-size: 1rem;
  align-items: center;
  justify-content: space-between;
}

.overWatchActivity .deviceInfoList .title .powerIcon{
  font-family: 'Seven Segment';

  position: relative;
  top: -12px;
  margin-inline-end: 8px;
}


.overWatchActivity .deviceInfoList p:first-child{
  font-family: 'Seven Segment';

  font-weight: 650;
  margin-inline-start: 8px;
}

.overWatchActivity .deviceInfoList p:last-child{
  font-size: 0.8rem;
}

.overWatchActivity .deviceInfoList .address{
  display: flex;
  gap: 5px;
  align-items: center;
}

.overWatchActivity .deviceInfoList .address img{
  max-width: 16px;
}

.overWatchActivity .deviceStats{
  color: white;
  max-width: unset ;
  animation: 2s ease-in fadeInActivity;
}

.overWatchActivity .deviceStats .deviceInfo span{
  font-weight: 300;
}

.overWatchActivity .deviceList{
  margin-top: 10px;
}

@keyframes fadeInActivity {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.overWatchActivity .deviceInfo,
.overWatchActivity .deviceInfo .info{
  font-size: 1.3rem;
}

.overWatchActivity .deviceStats .spinner{
  display: flex;
  justify-content: center;
  padding: 90px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
  color: white;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  animation: 2s ease-in glowBlue;
  background-color: #55b1e7 !important;
}

.overWatchActivity .noPadding{
  padding: unset;
}

@media screen and (min-width: 2000px) {
   :root{
    font-size: 20px;
  }

  .overWatchActivity .logo img{
    max-width: 200px;
  }

  .overWatchActivity .deviceInfoList .address img{
    max-width: 24px;
  }


  .overWatchActivity .deviceInfo h4{
    font-size: 2rem;
  }

  .overWatchActivity .deviceInfoList .address{
    align-items: flex-start;
  }
}
