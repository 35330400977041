.loginError{
    margin-top: 15px;
    color: #dc3545;

}

.fieldInvalid{
    color: #dc3545;

    font-size: 80%;
}

.formLabel{

    font-size: 14px;
}

.pointer{

    cursor: pointer;
}

.logoImg img{

    margin-top: 20%;
    margin-bottom: 10%;
    margin-left: 30%;
   width: 40%;
}

.loginForm{

    padding-left: 17%;
    padding-right: 17%;
}

.title{

    font-size: 15px;
    font-weight: bold;
    margin-bottom: 2%;
}

.formLabel{

    font-size: 12px;
    margin-top: 5%;

}

.changeStateMessage{
    margin-top: 2%;
    font-size: 12px;
    margin-bottom: 2%;
    margin-left: 2%;
    cursor: pointer;
    color: dodgerblue;
}

.loginBtn{
    margin-top: 4%;
    width: 100%;
}

#root{
    background-color: #171717;
    height: 100vh;
}

.loginPage{
    color: white;
}
