.row .gaugeRow{

}

.row .gaugeRow .col-3{

    padding-left: 4%;
    padding-right: 2%;
}

.gaugeCol h6{

    text-align: center;
    margin-bottom: 3%;
    margin-top: 17%;
    font-size: 23px;
}

.gaugeMin{

    color: grey;
    font-size: 12px;
}

.gaugeMax{

    color: grey;
    font-size: 12px;
}
