.deviceTabsBtn{

    padding-top: 1%;
}

.productPageImageBorder img {

    padding-top: 2%;
    max-height: 100%;
}

.deviceFont{

    margin-left: 1%;
    color: #99ddff;
    font-family: 'Seven Segment';
    font-weight: bold;

}

.itemTitle{


    font-size: 40px;
    margin-top: 2%;
    margin-bottom: 4%;
}

.productPageImageBorder {

    margin-left: 15%;
    height: 26vh
}

.deviceTabsBtn .nav-item {
    font-family: 'Seven Segment';

    text-align: center;
    align-items: center;
    width: 50%;
    box-shadow: 0px 0.8px lightgrey;
}

.deviceTabsBtn .nav-item  .tabSelected{

    color: #2ac4ea;
}

.deviceTabsBtn .nav-item  .tabSelected .tabDiv{

    color: #2ac4ea;
    font-weight: bolder;
    box-shadow: 0px 1px 0px #2ac4ea;
}

.tabDiv{

    height: 100%;
}

.tabDivSpan{
    margin-right: 11px ;
    font-size: 23px;
}

.tabDiv{

    padding-bottom: 10px;
    height: 100%;
}

.tabIcon{

    margin-bottom: 10px;
}

.deviceTabsBtn .nav-item:hover {

    color: #2ac4ea;

    box-shadow: 0px 1px 0px #2ac4ea;
}

.deviceTabsBtn .nav-item a {

    text-decoration: none;
    font-size: 11px;
    color: grey;
}

.overwatch .deviceInfoList .address{
    display: flex;
    gap: 5px;
    align-items: flex-start;
}

.cardRowTitle{
    font-size: 20px;
    margin-left: 5px;
}

.serviceCallChart{

    margin-top: 6%;
    margin-left: 15%;
}

.totalConsolesByUniverseChart{

    margin-left: 15%;
}

.totalConsolesByMonth{

    margin-right: 5%;

}

.patientsByUniverseChart{

    margin-top: 6%;
    margin-left: 10%;
    margin-bottom: 6%;
}

.seriesByUniverseChart{

    margin-top: 5%;
    margin-left: 10%;
    margin-bottom: 5%;
}

.pieChart{

    margin-left: 15%;
    margin-top: 5%;
    margin-bottom: 5%;

}

.totalLogsByMonth{

    margin-left: 10%;
    margin-top: 6%;
    margin-bottom: 6%;
    margin-right: 10%;

}

.totalStudiesByMonth{

    margin-left: 10%;
    margin-top: 6%;
    margin-bottom: 5%;
    margin-right: 10%;
}

.statisticData{

    margin-left: 12%;
    margin-right: 9%;
    padding-bottom: 3%;
}

.consolesTypesChart{

    margin-top: 7%;
    margin-left: 25%;
}

.leftChart{

    margin-top: 2%;

    margin-right: 5%;
}

.cardIcon svg{

    margcardClinicIconin-top: 4%;
    margin-left: 36%;
    color: #2ac4ea;

}

.cardClinicIcon svg{

    margcardClinicIconin-top: 4%;
    margin-left: 42%;
    color: #2ac4ea;
}

.cardDevicesIcon svg{

    margcardClinicIconin-top: 4%;
    margin-left: 42%;
    color: #2ac4ea;
}

.cardUsersIcon svg{

    margcardClinicIconin-top: 4%;
    margin-left: 44%;
    color: #00ace6;
}

.cardUsersData{

    margin-top: 8%;
    margin-left: 44%;
    font-size: 27px;
    font-weight: 600;

}

.cardDevicesData{

    margin-top: 8%;
    margin-left: 40%;
    font-size: 27px;
    font-weight: 600;

}

.cardDevicesDescription{

    margin-left: 40%;
    font-size: 12px;
}

.cardUsersDescription{

    margin-left: 43%;
    font-size: 12px;
}

.cardL{
    margin-top: 5px;
    margin-right: 1px;
    margin-bottom: 5px;


}

.cardLayout{

    margin-top: 2px;

}


.statisticsTitle h6{

    font-size: 12px;
    font-weight: bold;
    padding-top: 5%;
    margin-bottom: 4%;
}

.cardClinicsDescription{

    margin-top: 9%;

    margin-left: 40%;
    font-size: 16px;
}

.cardDescription{

    text-align: center;

    font-size: 16px;
    padding-bottom: 15px;
}

.cardData{

    margin-top: 7%;
    margin-bottom: 5%;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
}



.cardClinicsData{

    margin-top: 8%;
    margin-left: 39%;
    font-size: 23px;
    font-weight: 600;
}


.dashboardTitle{

    margin-left: 4%;
    margin-top: 38px;
}









